import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { rawTopicsFetcher } from 'core/fetchers/listPage';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import { denormalizeData } from 'core/utils/api';
import { getPage } from 'core/utils/url-helper';

import H1 from 'core/components/H1';
import ListPage from 'core/components/ListPage';

import PageLayout from 'site/components/PageLayout';
import RubricSelector from 'site/components/RubricSelector';
import SelectionList from 'site/components/SelectionList';

import { Indent } from 'site/components/Wrappers';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  RUBRICS_PAGE_LIMIT,
} from 'site/constants';


const title = 'Журнал — все статьи и новости';
const description = 'Все последние новости автомобилей, актуальные обзоры от экспертов, новинки авторынка в журнале на сайте quto.su';

function JournalPage({ rawData, commercial, isMobile }) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <PageLayout>
      <H1 dataQa='page-title'>Журнал</H1>
      <Indent bottom={isMobile ? VERTICAL_INDENT_MOBILE : 24} />
      <RubricSelector />
      <Indent bottom={vertical} />
      <ListPage
        title={title}
        description={description}
        rawData={rawData}
        limit={RUBRICS_PAGE_LIMIT}
        {...commercial.length && { 'excluded_ids': [commercial[0].id] }}
      >
        {({ content }) => {
          const result = [...content];

          if (commercial.length) {
            result.splice(1, 0, commercial[0]);
          }

          return <SelectionList topics={result} />;
        }}
      </ListPage>
    </PageLayout>
  );
}

JournalPage.propTypes = {
  rawData: PropTypes.object,
  isMobile: PropTypes.bool,
  commercial: PropTypes.array,
};

const dataProvider = resolve({
  rawData: rawTopicsFetcher({
    include: SelectionList.requiredPayloadImports,
    fields: SelectionList.requiredPayloadFields,
    limit: RUBRICS_PAGE_LIMIT,
  }),

  commercial: (props) => {
    const {
      location,
      bebopApi,
    } = props;
    const page = getPage(location.search);

    if (page === 1) {
      return bebopApi.getTopics({
        list: 'commercial',
        sort: 'list',
        include: SelectionList.requiredPayloadImports,
        fields: SelectionList.requiredPayloadFields,
        limit: 1,
      })
        .then(denormalizeData);
    }
    return [];
  },
});

export default withPageHocs(dataProvider)(withBreakpoint(JournalPage));
